// ORIGINAL WORKING CODE

import React, { useState, useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import Navbar from './Navbar'


function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setError(""); // Clear any previous errors

    //     try {
    //         await login(username, password);
    //         navigate("/SubscribersMain/1");
    //     } catch (err) {
    //         //   setError(err.message || 'Wrong Username or password');
    //         setError("Invalid Username or password");
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(""); // Clear any previous errors

        try {
            await login(username, password);
            navigate("/SubscribersMain/1");
        } catch (err) {
            // Display the error message from the backend
            const errorMessage = err.response?.data?.message || "Invalid Username or password";
            setError(errorMessage);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">

            <Navbar />

            <h1 className="text-4xl font-bold mb-8 text-gray-800 tracking-wide">
                <Link to="/" className="flex-shrink-0">
                    <div className="flex items-center text-5xl font-extrabold">
                        SQL
                        <div className="relative bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded  ms-2 flex items-center">
                            Guroo
                            <div className="absolute -top-3 -right-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-8 h-8"
                                    viewBox="0 0 24 24"
                                    style={{
                                        transform: "rotate(25deg)",
                                        color: "#1a1a1a"  // Darker color
                                    }}
                                >
                                    {/* Main cap */}
                                    <path
                                        fill="currentColor"
                                        d="M12 2L1 7l11 5 11-5-11-5zm0 6.5l-8-3.64V15l8 3.64 8-3.64V4.86l-8 3.64z"
                                    />
                                    {/* Tassel */}
                                    <path
                                        d="M21 7L21.5 8L27.5 24"
                                        stroke="currentColor"
                                        strokeWidth="1.2"
                                        fill="none"
                                        strokeLinecap="round"
                                    />
                                    {/* Tassel knot */}
                                    <circle
                                        cx="21"
                                        cy="7"
                                        r="0.5"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </Link>
            </h1>



            <div className="w-full max-w-md">
                <form
                    onSubmit={handleSubmit}
                    className="bg-white p-8 rounded-lg shadow-lg"
                >
                    <h2 className="text-2xl font-semibold mb-6 text-gray-700">Login</h2>
                    {error && (
                        <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-md">
                            {error}
                        </div>
                    )}
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Username
                        </label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="you@example.com"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-semibold mb-2">
                            Password
                        </label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="••••••••"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
                    >
                        Login
                    </button>
                    <p className="mt-6 text-center text-gray-600">
                        Don't have an account?{" "}
                        <Link to="/register" className="text-blue-600 hover:underline">
                            Register here
                        </Link>
                        .
                    </p>
                </form>
            </div>
        </div>
    );
}

export default Login;

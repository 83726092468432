import React, { useContext, useEffect, createContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
//  HashRouter
import { AuthProvider, AuthContext } from "./context/AuthContext";

import './App.css';
import axios from 'axios';
import Home from './Home';
import Main from './Main';
import Learnsql from './Learnsql';
import Register from './Register'
import Login from './Login';
import Pricing from './Pricing';
import Changepassword from './Changepassword';
import Checkout from './Checkout';
import SubscribersMain from './SubscribersMain';
import QuestionsOverview from './QuestionsOverview';
import ReactGA from 'react-ga';
import TermsandCondition from './TermsandCondition';
import Privacy from './Privacy';
// Initialize Google Analytics with your tracking code
ReactGA.initialize('UA-XXXXXXXXX-X');

// Add this line to track the initial pageview
ReactGA.pageview(window.location.pathname + window.location.search);

const URL = 'https://sqlguroo.com';

function PublicRoute({ element, allowIfLoggedIn = false }) {
    const { userId } = useContext(AuthContext);

    // If `allowIfLoggedIn` is true, don't redirect even if `userId` exists
    if (userId && !allowIfLoggedIn) {
        return <Navigate to="/" />;
    }

    return element;
}

function ProtectedRoute({ element }) {
    const { userId } = useContext(AuthContext);
    return userId ? element : <Navigate to="/login" />;
}



function App() {

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch(`${URL}/auth/check-session`, {
                    credentials: "include",
                });
                // console.log(response)
                if (response.status === 401) {
                    localStorage.removeItem("userId");
                    // window.location.href = '/login';
                }
            } catch (error) {
                console.error("Failed to check session", error);
            }
        };

        const intervalId = setInterval(checkSession, 1800000); // Check every 30 minutes

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);



    return (
        <AuthProvider>

            <Router>
                <div>
                    <Routes>
                        {/* {user ? <Chatwindow /> : <Login />} */}
                        <Route path="/" element={<Home />} />
                        <Route path="/question/:id" element={<Main />} />
                        {/* <Route exact path="/chatwindow" element={user ? <Chatwindow /> : <Navigate to="/Login" />} /> */}
                        {/* <Route exact path="/chatwindow" element={<Chatwindow />} /> */}
                        {/* default redirect to home page */}
                        {/* <Route path="/learnsql/:id" element={<Learnsql />} /> */}
                        {/* <Route path="/learnsql/:id" element={<Learnsql />} /> */}

                        <Route
                            path="/terms"
                            element={<PublicRoute allowIfLoggedIn={true} element={<TermsandCondition />} />}
                        />

                        <Route
                            path="/privacy"
                            element={<PublicRoute allowIfLoggedIn={true} element={<Privacy />} />}
                        />


                        <Route
                            path="/question/:id"
                            element={<PublicRoute allowIfLoggedIn={true} element={<Main />} />}
                        />

                        <Route
                            path="/learnsql/:id"
                            element={<ProtectedRoute allowIfLoggedIn={true} element={<Learnsql />} />}
                        />

                        <Route
                            path="/subscribersmain/:id"
                            element={<ProtectedRoute allowIfLoggedIn={true} element={<SubscribersMain />} />}
                        />

                        <Route
                            path="/questionsoverview"
                            element={<ProtectedRoute allowIfLoggedIn={true} element={<QuestionsOverview />} />}
                        />

                        <Route
                            path="/pricing"
                            element={<PublicRoute allowIfLoggedIn={true} element={<Pricing />} />}
                        />

                        <Route
                            path="/register"
                            element={<PublicRoute allowIfLoggedIn={true} element={<Register />} />}
                        />
                        <Route
                            path="/login"
                            element={<PublicRoute allowIfLoggedIn={true} element={<Login />} />}
                        />

                        <Route
                            path="/checkout"
                            element={<ProtectedRoute allowIfLoggedIn={true} element={<Checkout />} />}
                        />

                        <Route
                            path="/changepassword"
                            element={<ProtectedRoute element={<Changepassword />} />}
                        />



                        <Route path="*" element={<Navigate to="/" />} />

                    </Routes>
                </div>
            </Router>
        </AuthProvider>

    );
}

export default App;





import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useParams, useNavigate } from 'react-router-dom';

function Footer() {

    return (

        <footer className="bg-white border-t">
            <div className="max-w-screen-xl mx-auto px-4 py-8 md:px-8">
                {/* Logo and Description Section */}
                <div className="flex flex-col items-center mb-8">
                    {/* <Link to="/" className="mb-4">
                                        <h1 className="flex items-center text-4xl font-bold">
                                            <span className="text-gray-800">SQL</span>
                                            <span className="bg-gradient-to-r from-blue-600 to-blue-800 text-white text-xl font-semibold px-3 py-1 rounded-lg ml-2">
                                                Guroo
                                            </span>
                                        </h1>
                                    </Link> */}
                    <h1 className="text-4xl font-bold mb-8 text-gray-800 tracking-wide">
                        <Link to="/" className="flex-shrink-0">
                            <div className="flex items-center text-5xl font-extrabold">
                                SQL
                                <div className="relative bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded  ms-2 flex items-center">
                                    Guroo
                                    <div className="absolute -top-3 -right-3">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-8 h-8"
                                            viewBox="0 0 24 24"
                                            style={{
                                                transform: "rotate(25deg)",
                                                color: "#1a1a1a"  // Darker color
                                            }}
                                        >
                                            {/* Main cap */}
                                            <path
                                                fill="currentColor"
                                                d="M12 2L1 7l11 5 11-5-11-5zm0 6.5l-8-3.64V15l8 3.64 8-3.64V4.86l-8 3.64z"
                                            />
                                            {/* Tassel */}
                                            <path
                                                d="M21 7L21.5 8L27.5 24"
                                                stroke="currentColor"
                                                strokeWidth="1.2"
                                                fill="none"
                                                strokeLinecap="round"
                                            />
                                            {/* Tassel knot */}
                                            <circle
                                                cx="21"
                                                cy="7"
                                                r="0.5"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </h1>
                    <p className="text-gray-600 text-center max-w-md text-sm">
                        SQL Guroo is an interactive platform designed to help you master SQL through hands-on practice and real-world queries.
                    </p>
                </div>

                {/* Links and Copyright Section */}
                <div className="border-t pt-8">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                        <div className="text-sm text-gray-500 w-full md:w-auto text-center md:text-left">
                            &copy; {new Date().getFullYear()} SQL Guroo. All rights reserved.
                        </div>
                        <div className="flex space-x-6 w-full md:w-auto justify-center md:justify-end">
                            <a href="/privacy" className="text-sm text-gray-500 hover:text-blue-600 transition-colors">
                                Privacy Policy
                            </a>
                            <a href="/terms" className="text-sm text-gray-500 hover:text-blue-600 transition-colors">
                                Terms and Conditions
                            </a>
                            <a href="/terms" className="text-sm text-gray-500 hover:text-blue-600 transition-colors">
                                Refund Policy
                            </a>
                            <a href="/contact" className="text-sm text-gray-500 hover:text-blue-600 transition-colors">
                                Contact
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer;

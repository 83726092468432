import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "./context/AuthContext";
import Topbar from './components/Topbar';
import Footer from './components/Footer';
import Navbar from './Navbar';
const Privacy = () => {

    return (

        <div className="relative flex h-screen overflow-hidden justify-center">
            <div className="relative flex flex-col 
                            w-full 
                            max-w-[98%]            /* Default max width */
                            
                            2xl:max-w-[1820px]     /* 2XL screens */
                            mx-auto 
                            h-screen 
                            overflow-hidden"
            >


                {/* <div className="relative flex h-screen overflow-hidden justify-center">


                    <div className="relative flex flex-col w-10/12 h-screen overflow-y-scroll"> */}


                <div className="container mx-auto px-4 pt-16 overflow-hidden">

                    <div className="flex flex-col h-full justify-items-center items-center  border border-gray-200 rounded-lg bg-white shadow-sm  font-semibold overflow-y-scroll" >


                        <div className="max-w-3xl mx-auto p-6 my-4 bg-white shadow-md rounded-lg">
                            {/* <h1 className="text-3xl font-bold text-gray-800 mb-6">Terms and Conditions for SQLGuroo.com</h1> */}
                            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>


                            {/* <p className="text-gray-600 mb-4">
                                Welcome to SQLGuroo.com, your platform to learn and practice SQL questions, designed for starters, students, and enterprises. By accessing or using our platform, you agree to comply with the following terms and conditions. Please read them carefully.
                            </p>
                            <hr className="my-6" /> */}

                            <div className="prose prose-blue max-w-none">
                                <p className="text-gray-600 mb-6">
                                    At sqlguroo.com, your privacy is critically important to us. This Privacy Policy explains how we collect, use, and protect your information while using our platform. By accessing or using sqlguroo.com, you agree to the terms outlined in this policy.
                                </p>

                                <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    Information We Collect
                                </h2>

                                <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">
                                    Personal Data:
                                </h3>
                                <p className="text-gray-600 mb-4">
                                    While using our Service, we may ask you to provide certain personally identifiable information, which includes but is not limited to:
                                </p>
                                <ul className="list-disc pl-6 mb-6 text-gray-600">
                                    <li>Email address</li>
                                    <li>First name and last name</li>
                                    <li>Billing and payment information (processed through secure third-party services)</li>
                                    <li>Cookies and usage data</li>
                                </ul>

                                <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">
                                    Usage Data:
                                </h3>
                                <p className="text-gray-600 mb-4">
                                    We may also collect information your browser sends when you visit sqlguroo.com or access the platform via a mobile device. This may include:
                                </p>
                                <ul className="list-disc pl-6 mb-6 text-gray-600">
                                    <li>IP address</li>
                                    <li>Browser type and version</li>
                                    <li>Pages visited and time spent on each page</li>
                                    <li>Unique device identifiers</li>
                                    <li>Other diagnostic data</li>
                                </ul>

                                <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">
                                    Cookies and Tracking Technologies:
                                </h3>
                                <p className="text-gray-600 mb-4">
                                    We use cookies and similar technologies to enhance user experience, store preferences, and analyze service usage. Examples include:
                                </p>
                                <ul className="list-disc pl-6 mb-6 text-gray-600">
                                    <li>Session Cookies: To operate our Service.</li>
                                    <li>Preference Cookies: To remember your settings and preferences.</li>
                                    <li>Security Cookies: To maintain a secure browsing experience.</li>
                                    <li>Analytics and Advertising Cookies: To gather insights and display relevant ads.</li>
                                </ul>
                                <p className="text-gray-600 mb-6">
                                    You can configure your browser to reject cookies, but this may limit some functionality of our platform.
                                </p>

                                <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    How We Use Your Data
                                </h2>
                                <p className="text-gray-600 mb-4">
                                    We use the data collected for the following purposes:
                                </p>
                                <ul className="list-disc pl-6 mb-6 text-gray-600">
                                    <li>To provide and maintain the platform.</li>
                                    <li>To notify you of changes to the platform or policies.</li>
                                    <li>To enable interactive features such as practice modules and challenges.</li>
                                    <li>To provide customer support and troubleshoot issues.</li>
                                    <li>To analyze usage patterns and improve our content, features, and services.</li>
                                    <li>To send you newsletters, promotional offers, and updates (you can opt-out at any time).</li>
                                    <li>To process payments securely for subscriptions and services.</li>
                                    <li>To comply with legal obligations.</li>
                                </ul>

                                <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    Retention of Data
                                </h2>
                                <p className="text-gray-600 mb-6">
                                    We retain your Personal Data only for as long as necessary to fulfill the purposes outlined in this policy. Usage Data may be retained for internal analytics or to strengthen platform security.
                                </p>

                                <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    Data Sharing and Transfer
                                </h2>
                                <p className="text-gray-600 mb-4">
                                    Your data may be transferred to and maintained on servers outside your jurisdiction where data protection laws may differ. We take steps to ensure secure data handling and comply with applicable legal requirements for such transfers.
                                </p>
                                <p className="text-gray-600 mb-4">
                                    We may share your data:
                                </p>
                                <ul className="list-disc pl-6 mb-6 text-gray-600">
                                    <li>With trusted service providers, such as payment processors or analytics tools.</li>
                                    <li>If required by law or in response to valid legal processes.</li>
                                    <li>During mergers, acquisitions, or asset sales, as part of the transferred assets.</li>
                                    <li>With your consent for specific purposes.</li>
                                </ul>

                                <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    Security of Data
                                </h2>
                                <p className="text-gray-600 mb-6">
                                    We prioritize the security of your data and use commercially acceptable methods to protect it. However, no online transmission or storage method is 100% secure, and we cannot guarantee absolute security.
                                </p>

                                <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    Your Rights
                                </h2>
                                <p className="text-gray-600 mb-4">
                                    If you are a resident of the EU, California, or other jurisdictions with specific data protection laws, you have rights regarding your data, such as:
                                </p>
                                <ul className="list-disc pl-6 mb-6 text-gray-600">
                                    <li>The right to access, update, or delete your personal data.</li>
                                    <li>The right to object to processing or request data portability.</li>
                                    <li>The right to withdraw consent for communications or specific uses.</li>
                                    <li>The right to file complaints with a regulatory authority.</li>
                                </ul>
                                <p className="text-gray-600 mb-6">
                                    For any data requests or concerns, please email us at support@sqlguroo.com.
                                </p>

                                <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    Third-Party Services
                                </h2>
                                <p className="text-gray-600 mb-4">
                                    We use trusted third-party services for analytics, hosting, payment processing, and more. These include:
                                </p>
                                <ul className="list-disc pl-6 mb-6 text-gray-600">
                                    <li>Google Analytics: For website traffic analysis (Privacy Policy).</li>
                                    <li>Paddle: For secure payment processing (Privacy Policy).</li>
                                </ul>
                                <p className="text-gray-600 mb-6">
                                    These services handle your data according to their respective privacy policies.
                                </p>

                                <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    Behavioral Remarketing
                                </h2>
                                <p className="text-gray-600 mb-6">
                                    We may use remarketing services (e.g., Google Ads, Facebook Ads) to display relevant advertisements based on your past visits to our platform. You can opt out of these services via their settings or by adjusting your browser's ad preferences.
                                </p>

                                <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    Changes to This Policy
                                </h2>
                                <p className="text-gray-600 mb-6">
                                    We reserve the right to update this Privacy Policy as needed. Changes will be posted on this page with an updated "Last Updated" date. Please review the policy periodically to stay informed.
                                </p>

                                {/* <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">
                                    Contact Us
                                </h2>
                                <p className="text-gray-600 mb-4">
                                    If you have questions about this Privacy Policy or your data, contact us at:
                                </p>
                                <p className="text-gray-600">
                                    Email: <a href="mailto:support@sqlguroo.com" className="text-blue-600 hover:text-blue-800">support@sqlguroo.com</a>
                                </p> */}
                            </div>


                        </div>

                        <Navbar />
                        <Footer />

                    </div>
                </div>
                {/* </div>
                </div> */}
            </div>
        </div>
    );
};

export default Privacy; 
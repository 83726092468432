import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "./context/AuthContext";
import Topbar from './components/Topbar';
import Footer from './components/Footer';
import Navbar from './Navbar';
const TermsandCondition = () => {

    return (

        <div className="relative flex h-screen overflow-hidden justify-center">
            <div className="relative flex flex-col 
                            w-full 
                            max-w-[98%]            /* Default max width */
                            
                            2xl:max-w-[1820px]     /* 2XL screens */
                            mx-auto 
                            h-screen 
                            overflow-hidden"
            >


                {/* <div className="relative flex h-screen overflow-hidden justify-center">


                    <div className="relative flex flex-col w-10/12 h-screen overflow-y-scroll"> */}


                <div className="container mx-auto px-4 pt-16 overflow-hidden">

                    <div className="flex flex-col h-full justify-items-center items-center  border border-gray-200 rounded-lg bg-white shadow-sm  font-semibold overflow-y-scroll" >


                        <div className="max-w-3xl mx-auto p-6 my-4 bg-white shadow-md rounded-lg">
                            {/* <h1 className="text-3xl font-bold text-gray-800 mb-6">Terms and Conditions for SQLGuroo.com</h1> */}
                            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Terms and Conditions</h1>


                            <p className="text-gray-600 mb-4">
                                Welcome to SQLGuroo.com, your platform to learn and practice SQL questions, designed for starters, students, and enterprises. By accessing or using our platform, you agree to comply with the following terms and conditions. Please read them carefully.
                            </p>
                            <hr className="my-6" />

                            <div className="space-y-8">
                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">1. General Terms</h2>
                                    <p className="text-gray-600 mt-2">
                                        We reserve the right to modify, suspend, or discontinue any part of the platform, its content, or services at any time without notice. Your continued use of the platform constitutes acceptance of these changes.
                                    </p>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">2. Subscriptions</h2>
                                    <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                        <li><strong>Subscription Plans:</strong> SQLGuroo.com offers paid subscription plans billed on a monthly, or annual basis. Billing cycles are determined at the time of subscription.</li>
                                        <li><strong>Renewals:</strong> Subscriptions automatically renew at the end of each billing cycle unless canceled. You may cancel your subscription through your account settings or by contacting our support team.</li>
                                        <li><strong>Payment:</strong> A valid payment method is required to process subscription fees. By providing payment information, you authorize us to charge the subscription fees to your payment method.</li>
                                    </ul>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">3. Free Trial</h2>
                                    <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                        <li>SQLGuroo.com may offer free trials at its sole discretion.</li>
                                        <li>We reserve the right to modify or terminate free trial offers without prior notice.</li>
                                    </ul>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">4. Fee Changes</h2>
                                    <p className="text-gray-600 mt-2">
                                        SQLGuroo.com reserves the right to modify subscription fees. Fee changes will become effective at the end of the current billing cycle. Continued use after fee adjustments constitutes agreement to the revised charges.
                                    </p>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">5. Fair Usage Policy (FUP)</h2>
                                    <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                        <li>Our platform is designed to provide a reliable learning environment for all users.</li>
                                        <li>Excessive usage or misuse may impact others' experience. We may restrict or suspend accounts that violate fair usage policies, with or without prior notice.</li>
                                        <li>Sharing login credentials, especially for monetary gain, is prohibited and may result in account suspension or termination.</li>
                                    </ul>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">6. Prohibited Activities</h2>
                                    <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                        <li>Use the platform for illegal purposes or activities.</li>
                                        <li>Distribute spam, malware, or other harmful content.</li>
                                        <li>Share login credentials with multiple users.</li>
                                        <li>Overload, damage, or interfere with the platform’s operations.</li>
                                        <li>Use automated systems like bots to access the platform without prior written consent.</li>
                                    </ul>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">7. User Accounts</h2>
                                    <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                        <li>Users must be at least 12 years old to create an account.</li>
                                        <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                                        <li>Providing false or incomplete information may result in account suspension or termination.</li>
                                    </ul>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">8. Refund Policy</h2>
                                    <p className="text-gray-600 mt-2">
                                        All subscription purchases are final and non-refundable unless explicitly stated otherwise.
                                    </p>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">9. Intellectual Property</h2>
                                    <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                        <li>All content, features, and functionality on SQLGuroo.com are the exclusive property of SQLGuroo and its licensors.</li>
                                        <li>You may not reproduce, distribute, or create derivative works without written permission from SQLGuroo.</li>
                                    </ul>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">10. Analytics and Monitoring</h2>
                                    <p className="text-gray-600 mt-2">
                                        SQLGuroo.com uses analytics tools to monitor usage and improve services. For more information, refer to our Privacy Policy.
                                    </p>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">11. Links to Third-Party Websites</h2>
                                    <p className="text-gray-600 mt-2">
                                        Our platform may contain links to third-party websites. SQLGuroo.com is not responsible for the content, privacy policies, or practices of third-party websites.
                                    </p>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">13. Disclaimer of Warranties</h2>
                                    <p className="text-gray-600 mt-2">
                                        SQLGuroo.com is provided “as is” and “as available” without warranties of any kind. We do not guarantee uninterrupted or error-free service.
                                    </p>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">14. Limitation of Liability</h2>
                                    <p className="text-gray-600 mt-2">
                                        To the fullest extent permitted by law, SQLGuroo.com and its affiliates are not liable for any indirect, incidental, or consequential damages arising from your use of the platform.
                                    </p>
                                </section>

                                <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">15. Governing Law</h2>
                                    <p className="text-gray-600 mt-2">
                                        These terms and conditions are governed by and construed in accordance with the laws of India.
                                    </p>
                                </section>

                                {/* <section>
                                    <h2 className="text-2xl font-semibold text-gray-700">Contact Information</h2>
                                    <p className="text-gray-600 mt-2">
                                        For any questions or concerns about these terms, please contact us at <a href="mailto:support@sqlguroo.com" className="text-blue-500 underline">support@sqlguroo.com</a>.
                                    </p>
                                </section> */}
                            </div>
                        </div>

                        <Navbar />
                        <Footer />

                    </div>
                </div>
                {/* </div>
                </div> */}
            </div>
        </div>
    );
};

export default TermsandCondition; 
import React from 'react';
import { useTable, useRowSelect, useRowHover } from 'react-table';
import '../src/DataTable.css';

const DataTable = ({ data }) => {
  const columns = React.useMemo(
    () =>
      data && data.length > 0
        ? Object.keys(data[0]).map((key) => ({
          Header: key,
          accessor: key,
        }))
        : [],
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable({
    columns,
    data,
    useRowSelect,
    useRowHover
  });

  return (
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={row.isHovered ? 'hovered' : ''}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} >{cell.render('Cell')}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataTable;

import React, { useContext, useState } from "react";
import axios from "axios";

import { AuthContext } from "./context/AuthContext";
import { useNavigate, useParams, Link } from "react-router-dom";

// const URL = "http://116.203.206.211:5000";

const URL = 'https://sqlguroo.com';

function Changepassword() {
    const { userId } = useContext(AuthContext);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (newPassword.length < 8) {
            newErrors.newPassword = "Password must be at least 8 characters long";
        }
        if (newPassword !== confirmNewPassword) {
            newErrors.confirmNewPassword = "Passwords do not match";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const response = await axios.put(`${URL}/auth/update-password`, {
                userId,
                currentPassword,
                newPassword,
            });
            setMessage(response.data.message);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
        } catch (error) {
            setMessage(error.response?.data.message || "Error updating password");
        }
    };

    return (
        // <>
        <div className="min-h-screen flex flex-col items-center bg-gray-100">

            <div className="group fixed top-3 flex h-12 items-center rounded-3xl border border-slate-200 bg-slate-100/60 pr-1.5 shadow-lg shadow-black/5 backdrop-blur-lg dark:border-slate-700 dark:bg-black/70 dark:shadow-black/10 lg:top-3">
                <div className="absolute inset-0 overflow-hidden rounded-full">
                    <div
                        className="absolute -bottom-3 -left-10 h-6 w-20 rounded-full bg-white/70 opacity-0 blur-lg transition-opacity duration-200 group-hover:opacity-100 dark:bg-white/30"
                        style={{ transform: "translateX(564.521px) translateZ(0px)" }}
                    />
                </div>
                <ul className="relative flex items-center space-x-4 pl-4 pr-4">
                    <div className="mt-2 mb-2 font-semibold mr-14">
                        <a href="/">
                            <p className="flex justify-start text-2xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient">
                                SQL Guroo
                            </p>
                        </a>
                    </div>

                    <li className="relative">
                        <Link to="/">
                            <button className="relative flex h-7 items-center rounded px-2 font-bold focus:bg-white/30 focus:outline-none dark:focus:bg-white/10 text-slate-600 hover:text-black dark:text-slate-300 dark:hover:text-white">
                                Home
                            </button>
                        </Link>
                    </li>
                    <li className="relative">
                        <Link to="/learnsql/1">
                            <button className="relative flex h-7 items-center rounded px-2 font-bold focus:bg-white/30 focus:outline-none dark:focus:bg-white/10 text-slate-600 hover:text-black dark:text-slate-300 dark:hover:text-white">
                                Learn SQL
                            </button>
                        </Link>
                    </li>
                    <li className="relative">
                        <Link to="/question/1">
                            <button className="relative flex h-7 items-center rounded px-2 font-bold focus:bg-white/30 focus:outline-none dark:focus:bg-white/10 text-slate-600 hover:text-black dark:text-slate-300 dark:hover:text-white">
                                Practice SQL
                            </button>
                        </Link>
                    </li>
                    {/* <li className="relative">
                        <Link to="/pricing">
                            <button className="relative flex h-7 items-center rounded px-2 font-bold focus:bg-white/30 focus:outline-none dark:focus:bg-white/10 text-slate-600 hover:text-black dark:text-slate-300 dark:hover:text-white">
                                Pricing
                            </button>
                        </Link>
                    </li> */}

                </ul>

                {/* <button
                    className="relative h-9 flex-shrink-0 rounded-full bg-gradient-to-b from-indigo-400 to-indigo-500 p-px shadow shadow-indigo-500/25 focus:outline-none focus:ring-1 focus:ring-white/30"
                    type="button"
                >
                    <Link to="/login">
                        <div className="flex h-full items-center space-x-2 rounded-full bg-indigo-500 text-indigo-50 hover:bg-[#7375EE] font-bold  pl-4 pr-2.5">
                            <span className="dark:drop-shadow">Login</span>
                            <svg
                                height={10}
                                viewBox="0 0 20 10"
                                className="fill-current opacity-50 dark:drop-shadow text-white"
                                fill="current"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.5 5C0.5 4.44772 0.947715 4 1.5 4L18.5 4C19.0523 4 19.5 4.44772 19.5 5C19.5 5.55229 19.0523 6 18.5 6L1.5 6C0.947715 6 0.5 5.55228 0.5 5Z"
                                    fill="current"
                                    opacity={0}
                                    transform-origin="10px 5px"
                                    style={{ transform: "scaleX(0)", transformOrigin: "10px 5px" }}
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.2071 0.292893C14.8166 -0.0976311 14.1834 -0.0976311 13.7929 0.292893C13.4024 0.683418 13.4024 1.31658 13.7929 1.70711L17.0858 5L13.793 8.29274C13.4025 8.68327 13.4025 9.31643 13.793 9.70696C14.1836 10.0975 14.8167 10.0975 15.2073 9.70696L19.2071 5.70711L19.2073 5.70696C19.5978 5.31643 19.5978 4.68327 19.2073 4.29274C19.2023 4.28781 19.1974 4.28294 19.1924 4.27814L15.2071 0.292893Z"
                                    fill="current"
                                    transform-origin="16.500100135803223px 4.99993222951889px"
                                    style={{
                                        transform: "translateX(-7px)",
                                        transformOrigin: "16.5001px 4.99993px"
                                    }}
                                />
                            </svg>
                        </div>
                    </Link>
                </button> */}
            </div>

            <div className="mt-24 p-6 w-full max-w-2xl mx-auto">
                <div className="bg-white rounded-xl shadow-xl overflow-hidden">
                    {/* Header Section */}
                    <div className="bg-gradient-to-r from-blue-600 to-blue-800 p-6">
                        <h2 className="text-2xl font-bold text-white text-center">
                            Update Password
                        </h2>
                        <p className="text-blue-100 text-center mt-2">
                            Keep your account secure with a strong password
                        </p>
                    </div>

                    {/* Form Section */}
                    <form onSubmit={handleSubmit} className="p-8">
                        <div className="space-y-6">
                            {/* Current Password */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Current Password
                                </label>
                                <input
                                    type="password"
                                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                            </div>

                            {/* New Password */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                {errors.newPassword && (
                                    <p className="text-red-500 text-sm mt-1">{errors.newPassword}</p>
                                )}
                            </div>

                            {/* Confirm New Password */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Confirm New Password
                                </label>
                                <input
                                    type="password"
                                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                />
                                {errors.confirmNewPassword && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.confirmNewPassword}
                                    </p>
                                )}
                            </div>

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-200"
                            >
                                Update Password
                            </button>

                            {/* Message Display */}
                            {message && (
                                <div className="mt-4 p-4 bg-blue-50 rounded-lg text-center text-blue-800">
                                    {message}
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div >
        // </>
    );
}

export default Changepassword;

import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "./context/AuthContext";
import Topbar from './components/Topbar';

const QuestionsOverview = () => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userId, firstName } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axios.get('https://sqlguroo.com/subapi/questions-status', {
                    params: { userId }
                });
                console.log('response from questions-status', response.data)
                setQuestions(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching questions:', error);
                setLoading(false);
            }
        };

        if (userId) {
            fetchQuestions();
        }
    }, [userId]);

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    return (

        <div className="relative flex h-screen overflow-hidden justify-center">


            {/* <div className="relative flex flex-col w-10/12 h-screen overflow-hidden"> */}

            <div className="relative flex flex-col 
                w-full 
                max-w-[98%]            /* Default max width */
                
                2xl:max-w-[1820px]     /* 2XL screens */
                mx-auto 
                h-screen 
                overflow-hidden"
            >

                <Topbar />

                <div className="container mx-auto px-4 py-8">
                    {/* Stats Summary */}
                    <div className="mb-8 bg-white rounded-lg shadow p-6">
                        <div className="flex justify-between items-center">
                            <h1 className="text-2xl font-bold text-gray-800">SQL Practice Questions</h1>
                            <div className="flex items-center gap-4">
                                <div className="text-center">
                                    <p className="text-sm text-gray-600">Total Questions</p>
                                    <p className="text-2xl font-bold text-gray-800">{questions.length}</p>
                                </div>
                                <div className="text-center px-4 border-l border-gray-200">
                                    <p className="text-sm text-gray-600">Solved</p>
                                    <p className="text-2xl font-bold text-green-600">
                                        {questions.filter(q => q.isSolved).length}
                                    </p>
                                </div>
                                <div className="text-center px-4 border-l border-gray-200">
                                    <p className="text-sm text-gray-600">Completion</p>
                                    <p className="text-2xl font-bold text-blue-600">
                                        {Math.round((questions.filter(q => q.isSolved).length / questions.length) * 100)}%
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Progress bar */}
                        <div className="mt-4 w-full bg-gray-200 rounded-full h-2.5">
                            <div
                                className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                                style={{
                                    width: `${(questions.filter(q => q.isSolved).length / questions.length) * 100}%`
                                }}
                            ></div>
                        </div>
                    </div>

                    <div className="overflow-x-auto shadow-md rounded-lg">
                        <table className="min-w-full table-auto">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        #
                                    </th>
                                    {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Title
                                    </th> */}
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Description
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Difficulty
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Score
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {questions.map((question) => (
                                    <tr
                                        key={question.id}
                                        className="hover:bg-gray-50 cursor-pointer"
                                        onClick={() => navigate(`/subscribersmain/${question.id}`)}
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {question.id}
                                        </td>
                                        {/* <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">
                                                {question.title}
                                            </div>
                                        </td> */}
                                        <td className="px-6 py-4">
                                            <div className="text-sm text-gray-900">
                                                {question.question.substring(0, 100)}...
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 py-1 text-xs font-medium rounded-full ${question.difficulty === 'Easy' ? 'bg-green-100 text-green-800' :
                                                question.difficulty === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                                                    'bg-red-100 text-red-800'
                                                }`}>
                                                {question.difficulty}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {question.isSolved ? (
                                                <span className="px-2 py-1 bg-green-100 text-green-700 text-xs font-medium rounded-full">
                                                    Solved ✓
                                                </span>
                                            ) : (
                                                <span className="px-2 py-1 bg-gray-100 text-gray-600 text-xs font-medium rounded-full">
                                                    Unsolved
                                                </span>
                                            )}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {question.isSolved ? `${question.score}/100` : '-'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionsOverview; 
import React, { useContext, useState, useRef, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from "./context/AuthContext";
import { FcPrevious, FcNext } from 'react-icons/fc';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react';

import './App.css';
import axios from 'axios';
import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { sql, SQLDialect } from "@codemirror/lang-sql";
import { EditorView, keymap } from "@codemirror/view";
import Split from 'react-split'
import DataTable from './DataTable'; // Import your DataTable component
import Home from './Home';
// const deepEqual = require('deep-equal');
// var deepEqual = require('fast-deep-equal');
import { BsListCheck } from 'react-icons/bs';
import { BiLogoPostgresql } from "react-icons/bi";
import { SiPostgresql } from "react-icons/si";
import { ToastContainer, toast } from 'react-toastify';
import { FaPlay } from "react-icons/fa6";

import 'react-toastify/dist/ReactToastify.css';
import logo from './logo_img.png';


const lessons_array = [
    {
        "id": 1,
        "Lesson": "1. SELECT All"
    },
    {
        "id": 2,
        "Lesson": "2. SELECT column"
    },
    {
        "id": 3,
        "Lesson": "3. WHERE"
    },
    {
        "id": 4,
        "Lesson": "4. ORDER BY"
    },
    {
        "id": 5,
        "Lesson": "5. LIKE"
    },
    {
        "id": 6,
        "Lesson": "6. IN"
    },
    {
        "id": 7,
        "Lesson": "7. BETWEEN"
    },
    {
        "id": 8,
        "Lesson": "8. AND"
    },
    {
        "id": 9,
        "Lesson": "9. OR"
    },
    {
        "id": 10,
        "Lesson": "10. NOT"
    },
    {
        "id": 11,
        "Lesson": "11. IS NULL"
    },
    {
        "id": 12,
        "Lesson": "12. COUNT"
    },
    {
        "id": 13,
        "Lesson": "13. SUM"
    },
    {
        "id": 14,
        "Lesson": "14. MAX/MIN"
    },
    {
        "id": 15,
        "Lesson": "15. AVG "
    },
    {
        "id": 16,
        "Lesson": "16. GROUP BY"
    },
    {
        "id": 17,
        "Lesson": "17. HAVING"
    },
    {
        "id": 18,
        "Lesson": "18. CASE"
    },
    {
        "id": 19,
        "Lesson": "19. DISTINCT"
    },
    {
        "id": 20,
        "Lesson": "20. INNER JOIN"
    },
    {
        "id": 21,
        "Lesson": "21. FULL JOIN"
    },
    {
        "id": 22,
        "Lesson": "22. LEFT JOIN"
    },
    {
        "id": 23,
        "Lesson": "23. RIGHT JOIN"
    },
    {
        "id": 24,
        "Lesson": "24. UNION"
    },
    {
        "id": 25,
        "Lesson": "25. UNION ALL"
    },
    {
        "id": 26,
        "Lesson": "26. RANK"
    },
    {
        "id": 27,
        "Lesson": "27. DENSE_RANK"
    },
    {
        "id": 28,
        "Lesson": "28. ROW_NUMBER"
    }
]


function Learnsql() {
    const [sqlQuery, setSqlQuery] = useState('');
    const [results, setResults] = useState([]);
    const [correct, setCorrect] = useState('');
    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState();
    const [queryerror, setQueryerror] = useState('');
    const { id } = useParams();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 650);
    const [selectedItem, setSelectedItem] = useState(null);

    const navigate = useNavigate();
    const { logout, userId, firstName } = useContext(AuthContext);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 650);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures that the effect runs only once on mount



    const backendUrl = 'http://195.35.23.51:3001';


    const handlePrevPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const prevPage = parseInt(id, 10) - 1;
        // Redirect to the next page
        if (prevPage < 1) {
            return;
        }
        window.location.href = `/learnsql/${prevPage}`;
    };

    const handleNextPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const nextPage = parseInt(id, 10) + 1;
        // Redirect to the next page
        if (nextPage >= 10000) {
            return;
        }
        window.location.href = `/learnsql/${nextPage}`;
    };



    const handleQueryChange = (e) => {
        setSqlQuery(e);
    };



    const getLessonData = async () => {
        try {
            const response = await axios.get(`https://sqlguroo.com/api/get-lesson/${id}`);
            const specificData = response.data;
            setQuestion(specificData)
            console.log(specificData);
        } catch (error) {
            console.error('Error fetching specific data:', error);
        }
    };



    const executeQuery = async () => {
        setLoading(true);
        setCorrect('')
        setButtonDisabled(true);

        setTimeout(async () => {

            if (/DROP|ALTER/i.test(sqlQuery)) {
                setQueryerror('Only Select statements are allowed');
                console.log('Only Select statements are allowed')
                setResults([])
                setTimeout(async () => {
                    setButtonDisabled(false);
                }, 1400);
                return;
            }

            try {

                // let res = await axios.post('https://sqlguru-service.onrender.com/executeQuery/', { sqlQuery })

                let res = await axios.post('https://sqlguroo.com/api/executeQuery/', { sqlQuery }
                    , {
                        timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
                    }
                )

                let { data } = res.data;
                setLoading(false);
                setResults(res.data)
                // console.log(res.data)
                console.log(results)
                setQueryerror('')
            }
            catch (e) {
                if (axios.isCancel(e)) {
                    console.log('Request canceled:', e.message)
                    setQueryerror(e.message)
                    setResults([])
                } else {
                    console.log(e.response ? e.response.data.error : e)
                    setQueryerror(e.response ? e.response.data.error : 'Unknown Error')
                    setResults([])
                }
            }

            setButtonDisabled(false);

        }, 1400);
    };


    function areTablesEqual(tableA, tableB) {
        // Check if the tables have the same number of rows
        if (tableA.length !== tableB.length) {
            return false;
        }

        // Create arrays to store sorted row data for comparisonss
        const sortedTableA = [];
        const sortedTableB = [];

        // Sort the rows in both tables by their values
        for (const row of tableA) {
            const sortedRow = Object.values(row).sort();
            sortedTableA.push(sortedRow);
        }

        for (const row of tableB) {
            const sortedRow = Object.values(row).sort();
            sortedTableB.push(sortedRow);
        }

        // Sort the entire array to ensure consistent order
        sortedTableA.sort();
        sortedTableB.sort();

        // Compare the sorted arrays
        for (let i = 0; i < sortedTableA.length; i++) {
            for (let j = 0; j < sortedTableA[i].length; j++) {
                if (sortedTableA[i][j] !== sortedTableB[i][j]) {
                    return false;
                }
            }
        }

        return true;
    }




    const submitQuery = async () => {
        if (results.length !== 0 && question !== null) {
            // Perform your processing on 'result' here
            // For example, you can log it or update other state variables

            setButtonDisabled(true)
            setCorrect('')

            setTimeout(async () => {

                try {
                    const jsonString1 = JSON.stringify(question[0].output, null, 2);
                    const jsonString2 = JSON.stringify(results, null, 2);

                    console.log(jsonString1)
                    console.log(jsonString2)

                    // const areEqual = jsonString1 === jsonString2;

                    const areEqual = areTablesEqual(question[0].output, results);


                    if (areEqual) {
                        setCorrect('Correct');
                    }
                    else {
                        setCorrect('Incorrect');
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    setCorrect('false');
                }
                setButtonDisabled(false)

            }, 1400);

        }
    };




    useEffect(() => {
        getLessonData()
    }
        , []);


    const KEYWORDS = [
        "select",
        "from",
        "where",
        "and",
        "or",
        "not",
        "in",
        "between",
        "contains",
        "array",
        "limit",
        "offset",
        "union",
        "intersect",
        "except",
        "order by",
        "asc",
        "desc"
    ];

    const TENSORS = ["images", "labels"];

    const dialect = SQLDialect.define({
        keywords: KEYWORDS.join(" "),
        builtin: TENSORS.join(" ")
    });


    const handleLogout = async () => {
        localStorage.clear();
        await logout();
        // navigate("/");
    };


    return (
        <div className="relative flex h-screen overflow-hidden justify-center">
            {/* // Render this div for large screens */}

            {/* <div className="relative flex flex-col w-10/12 h-screen overflow-hidden "> */}

            <div className="relative flex flex-col 
                    w-full 
                    max-w-[98%]            /* Default max width */
                    
                    2xl:max-w-[1820px]     /* 2XL screens */
                    mx-auto 
                    h-screen 
                    overflow-hidden"
            >
                {/* <div className="navbar bg-base-100">
                    <div className="navbar-start">
                        <Link to="/" >
                            <img src={logo} alt="Logo" className="w-64 sm:mx-auto" />
                        </Link>

                    </div >

                    <div className="navbar-end">
                        <h1 className="normal-case font-semibold hidden sm:block text-base xl:text-xl">
                            Practice, Learn, and Master SQL!
                        </h1>
                    </div>

                </div > */}




                <nav className="bg-white border-b border-gray-200 px-4 py-2.5 shadow-sm relative z-50">
                    <div className="flex justify-between items-center w-full">
                        {/* Left side: Logo and Navigation */}
                        <div className="flex items-center gap-2">

                            <Link to="/" className="flex-shrink-0">
                                <div className="flex items-center text-5xl font-extrabold">
                                    SQL
                                    <div className="relative bg-blue-100 text-blue-800 text-xl font-semibold me-2 px-2.5 py-0.5 rounded  ms-2 flex items-center">
                                        Guroo
                                        {firstName ? `- ${firstName}` : ""}

                                        <div className="absolute -top-3 -right-3">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-8 h-8"
                                                viewBox="0 0 24 24"
                                                style={{
                                                    transform: "rotate(25deg)",
                                                    color: "#1a1a1a"  // Darker color
                                                }}
                                            >
                                                {/* Main cap */}
                                                <path
                                                    fill="currentColor"
                                                    d="M12 2L1 7l11 5 11-5-11-5zm0 6.5l-8-3.64V15l8 3.64 8-3.64V4.86l-8 3.64z"
                                                />
                                                {/* Tassel */}
                                                <path
                                                    d="M21 7L21.5 8L27.5 24"
                                                    stroke="currentColor"
                                                    strokeWidth="1.2"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                />
                                                {/* Tassel knot */}
                                                <circle
                                                    cx="21"
                                                    cy="7"
                                                    r="0.5"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            {/* Navigation Buttons */}


                            <div className="flex justify-center">
                                <nav aria-label="Pagination">
                                    <ul className="inline-flex items-center space-x-1 rounded-md text-sm">
                                        <li>
                                            <button onClick={handlePrevPage} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                                                </svg>
                                                <span>Previous</span>
                                            </button>
                                        </li>
                                        <li>
                                            <span className="inline-flex items-center rounded-md bg-white px-4 py-2 text-gray-500">Lesson <b className="mx-1">{id}</b> of <b className="ml-1">28</b></span>
                                        </li>
                                        <li>
                                            <button onClick={handleNextPage} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                                <span>Next</span>
                                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                                </svg>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        {/* Right side: User Menu */}
                        <div className="flex items-center">
                            <Menu as="div" className="relative ml-3">
                                <MenuButton className="flex items-center gap-2 p-2 rounded-full hover:bg-gray-100 transition-colors">
                                    {/* User Avatar */}
                                    <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center">
                                        <span className="text-white text-sm font-medium">
                                            {/* Get first letter of user's name */}
                                            {localStorage.getItem('firstName')?.charAt(0) || 'U'}
                                        </span>
                                    </div>
                                    {/* Dropdown Arrow */}
                                    <svg className="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                </MenuButton>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <MenuItems className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {/* User Info */}
                                        <div className="px-4 py-2 border-b border-gray-100">
                                            <p className="text-sm font-medium text-gray-900">
                                                {localStorage.getItem('firstName') || 'User'}
                                            </p>
                                            <p className="text-xs text-gray-500">
                                                {localStorage.getItem('username') || 'user@example.com'}
                                            </p>
                                        </div>

                                        {/* Menu Items */}
                                        <MenuItem>
                                            {({ active }) => (
                                                <Link
                                                    to="/profile"
                                                    className={`${active ? 'bg-gray-100' : ''
                                                        } flex items-center px-4 py-2 text-sm text-gray-700`}
                                                >
                                                    <svg className="mr-3 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                        />
                                                    </svg>
                                                    Profile
                                                </Link>
                                            )}
                                        </MenuItem>

                                        <MenuItem>
                                            {({ active }) => (
                                                <Link
                                                    to="/settings"
                                                    className={`${active ? 'bg-gray-100' : ''
                                                        } flex items-center px-4 py-2 text-sm text-gray-700`}
                                                >
                                                    <svg className="mr-3 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                        />
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>
                                                    Settings
                                                </Link>
                                            )}
                                        </MenuItem>

                                        <div className="border-t border-gray-100">
                                            <MenuItem>
                                                {({ active }) => (
                                                    <button
                                                        onClick={handleLogout}

                                                        className={`${active ? 'bg-gray-100' : ''
                                                            } flex w-full items-center px-4 py-2 text-sm text-red-700`}
                                                    >
                                                        <svg className="mr-3 h-5 w-5 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                                            />
                                                        </svg>
                                                        Sign out
                                                    </button>
                                                )}
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </nav>






                {/* <div className="flex justify-center">
                    <nav aria-label="Pagination">
                        <ul className="inline-flex items-center space-x-1 rounded-md text-sm">
                            <li>
                                <button onClick={handlePrevPage} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                                    </svg>
                                    <span>Previous</span>
                                </button>
                            </li>
                            <li>
                                <span className="inline-flex items-center rounded-md bg-white px-4 py-2 text-gray-500">Lesson <b className="mx-1">{id}</b> of <b className="ml-1">28</b></span>
                            </li>
                            <li>
                                <button onClick={handleNextPage} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                    <span>Next</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div> */}



                <div class="mx-auto w-full h-full overflow-auto py-2">
                    <div class="h-full divide-y divide-gray-100 overflow-hidden border border-gray-200 rounded-md bg-white shadow-sm flex flex-row">


                        <div className="overflow-auto rounded-xl border border-gray-100 bg-gray-50 p-1 w-54">
                            <ul className="flex flex-col items-center gap-2 text-sm font-medium ">
                                {lessons_array.map((item, index) => (
                                    <li key={index} className="w-full">
                                        <a
                                            href={`/learnsql/${item.id}`}
                                            className={`flex items-start justify-left gap-2 rounded-lg px-3 py-2 text-xs  2xl:text-sm	
                                                        ${selectedItem === index || item.id === parseInt(id, 10)
                                                    ? 'text-gray-700 bg-white shadow'
                                                    : 'text-gray-500 hover:bg-white hover:text-gray-700 hover:shadow'
                                                }`}
                                            onClick={() => setSelectedItem(index)}
                                        >
                                            {item.Lesson}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>



                        <Split
                            // class="wrap"
                            sizes={[50, 50]}
                            minSize={100}
                            expandToMin={false}
                            gutterSize={10}
                            gutterAlign="center"
                            snapOffset={30}
                            dragInterval={1}
                            direction="horizontal"
                            cursor="col-resize"
                            className="split flex flex-row overflow-hidden h-full p-1 flex-1"
                        >

                            <div className="border border-gray-300 rounded-md bg-white shadow-sm p-3 overflow-auto flex">


                                <div className="px-3 flex-1">
                                    <div className="overflow-auto   flex flex-row justify-between">


                                        <dialog id="my_modal_3" className="modal">
                                            <div className="modal-box">
                                                <form method="dialog">
                                                    {/* if there is a button in form, it will close the modal */}
                                                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                                </form>
                                                <h3 className="font-bold text-lg">PostgreSQL Syntax</h3>
                                                <p className="py-4">1. Use PostgreSQL 14 version for SQL syntax</p>
                                                {/* <p className="py-4">2. Use 'public' before the table name. e.g. SELECT * FROM public.orders;</p> */}
                                                <p className="py-4">2. To apply date condition use, - date_column = date '2020-01-01'</p>
                                                <p className="py-4">3. To get the month from date use - extract(month from date_column)</p>
                                            </div>
                                        </dialog>
                                    </div>


                                    <div className="mt-3 mb-3 font-semibold">
                                        <p className="flex justify-start text-xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient">{question ? question[0].lesson : <span className="loading loading-infinity loading-lg"></span>}</p>
                                        {/* <p className="flex justify-start">{question ? question[0].lesson : <span className="loading loading-infinity loading-lg"></span>}</p> */}
                                    </div>
                                    {/* <h2 className="mt-8 mb-3 font-semibold"> Statement </h2> */}
                                    <div className="mt-3 mb-3">
                                        {question ? question[0].content : ''}
                                    </div>
                                    <h2 className="mt-8 mb-3 font-semibold"> Try this query </h2>
                                    <div className="mt-3 mb-3">
                                        {/* {question ? question[0].code : ''} */}
                                        <CodeMirror
                                            // className='codemirror_box'
                                            className=" h-full overflow-auto"
                                            extensions={[sql({ dialect }), EditorView.lineWrapping]}
                                            // theme={codeTheme}
                                            // extensions={[sql({ dialect })]}
                                            height="4rem"
                                            // placeholder="SELECT * FROM public.orders"
                                            value={question ? question[0].code : ''}
                                            // onChange={handleQueryChange}
                                            options={{ readOnly: true }}
                                        // theme={"dark"}
                                        />
                                    </div>
                                    <h2 className="mt-8  mb-3 font-semibold"> Explanation </h2>
                                    <div className="mt-3 mb-3">
                                        {question ? question[0].explain : ''}
                                    </div>



                                </div>

                            </div>




                            <div className="border border-gray-200 rounded-md bg-white shadow-sm p-3 ">


                                <Split
                                    // class="wrap2"
                                    sizes={[50, 50]}
                                    minSize={200}
                                    expandToMin={false}
                                    gutterSize={10}
                                    gutterAlign="center"
                                    snapOffset={30}
                                    dragInterval={1}
                                    direction="vertical"
                                    cursor="row-resize"
                                    className="overflow-hidden h-full"
                                >

                                    <div className="border border-gray-300 rounded-md bg-white shadow-sm  flex flex-col h-full p-1">

                                        {/* <p> {'Example syntax : SELECT * FROM ' + '<' + 'tablename' + '>' + '  e.g. table name - public.cars'} </p> */}
                                        <p className="flex justify-start text-sm font-bold"> {'SQL Play Ground'} </p>

                                        <CodeMirror
                                            // className='codemirror_box'
                                            className=" h-full overflow-auto"

                                            indentWithTab={false}
                                            // theme={codeTheme}
                                            extensions={[sql({ dialect })]}
                                            // height="14rem"
                                            placeholder="SELECT * FROM public.cars"
                                            value={sqlQuery}
                                            onChange={handleQueryChange}
                                        />

                                        <div className=" flex flex-row px-2">
                                            {/* <button onClick={executeQuery} type="button" class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
                                                        Execute Query
                                                    </button> */}
                                            <div className=" flex text-sm 2xl:text-base font-semibold w-1/4 justify-center items-center overflow-auto">
                                                {/* <h2> {correct}</h2> */}

                                                <h2>
                                                    {isButtonDisabled ?
                                                        <span className="loading loading-infinity loading-lg"></span>

                                                        : ''
                                                    }
                                                </h2>
                                                <h2>

                                                    {correct === 'Correct' && <p className="text-green-600 font-bold">Your answer is Correct!</p>}


                                                    {correct === 'Incorrect' && <p className="text-red-600 font-bold">Your answer is Wrong </p>}



                                                </h2>
                                            </div>

                                            <div className=" flex text-sm 2xl:text-base font-semibold w-1/4 justify-center items-center overflow-auto ">
                                                <h3>
                                                    {queryerror ? queryerror : ''}
                                                </h3>
                                            </div>


                                            <div className="w-1/4 flex justify-center">
                                                {/* <button onClick={executeQuery} type="button" disabled={isButtonDisabled} className="btn btn-neutral btn-sm xl:btn-sm 2xl:btn-md">
                                                        Run
                                                    </button> */}

                                                {/* <button type="button" onClick={executeQuery} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                                        <span>Run</span>
                                                        <FaPlay />
                                                    </button> */}

                                                <button type="button" onClick={executeQuery} className="inline-flex items-center gap-1.5 rounded-lg border border-green-500 bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white shadow-sm transition-all hover:border-green-700 hover:bg-green-700 focus:ring focus:ring-green-200 disabled:cursor-not-allowed disabled:border-green-300 disabled:bg-green-300">
                                                    <FaPlay />
                                                    <span>Run</span>
                                                </button>

                                            </div>

                                            {/* <div className="w-1/4 flex justify-center">
                                                        <button onClick={submitQuery} type="button" disabled={isButtonDisabled} className="btn btn-primary btn-sm xl:btn-sm 2xl:btn-md">
                                                            Submit
                                                        </button>
                                                    </div> */}

                                        </div>

                                    </div>


                                    <div className="overflow-auto">


                                        <div className="flex justify-start w-full 
                                                  " >
                                            {results ?
                                                <DataTable data={results}
                                                />
                                                : <span className="loading loading-infinity loading-lg"></span>
                                            }
                                        </div>


                                    </div>

                                </Split>

                            </div>

                        </Split>


                    </div>
                </div>




            </div >

        </div >

    );
}

export default Learnsql;




